//Loa đèn cảnh báo
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box, Card, Typography, CircularProgress } from '@mui/material';
import { sensorMapped } from '../../utils/sensorMapping';
import DefaultNode from '../../assets/sensor/default-node.svg';
import { ControlLocationNodeType, useUpdateZoneControlMutation } from '../../services/control.service';
import { INodeType } from '../../services/node.service';
import { getMinutesDiffNow } from '../../utils/UtilsFunctions';
import { Switch } from 'common/Switch/Switch';
import Zone from '../../assets/sensor/zone.svg';
import { useSnackbar } from 'hooks/useSnackbar';
import { useAuth } from 'hooks/useAuth';

const DLNodeCard = styled(Card)({
  cursor: 'pointer',
  backgroundColor: '#F6F9FC',
  boxShadow: 'none',
  border: '1.5px solid #EEF2FA',
  borderRadius: '8px',
  padding: '16px 16px 8px',
  position:'relative'
});

const LoadingCard = styled(Card)({
  cursor: 'pointer',
  backgroundColor: '#d1d1d17c',
  boxShadow: 'none',
  borderRadius: '8px',
  position:'absolute',
  top:0,
  left:0,
  width:'100%',
  height:'100%',
  zIndex:1,
  display:"flex", 
  justifyContent:"center", 
  alignItems:"center",
});

type Props = {
  data: ControlLocationNodeType;
  nodeTypes: INodeType[];
  onClickCard: () => void;
  showPin?: boolean;
  showStatus?: boolean;
};

export const NodeCard = ({ data, nodeTypes, onClickCard, showPin = true, showStatus = true }: Props) => {
  const nodeType = nodeTypes.find((item) => item.id === data.node_type_id);
  const nodeCodeType = nodeType?.code.split('-')[1];

  const status = data.state && getMinutesDiffNow(data.state?.timestamp) < 130;

  return (
    <DLNodeCard onClick={onClickCard}>
      <Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
        {showStatus && (
          <Box>
            {status ? <img src={sensorMapped.power_on_dot} alt="" /> : <img src={sensorMapped.power_off_dot} alt="" />}
          </Box>
        )}

        {showPin ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box style={{ fontSize: '12px' }}>{data.state?.battery || 0}%</Box>
            <img style={{ width: '20px', margin: '5px' }} src={sensorMapped.power_100} alt="" />
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center"></Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box style={{ minHeight: 78 }}>
          {nodeCodeType && (sensorMapped as any)[nodeCodeType] ? (
            <img src={(sensorMapped as any)[nodeCodeType]} alt="" />
          ) : (
            <img src={DefaultNode} alt="" />
          )}
        </Box>
        <Box mt={1} minHeight={'48px'}>
          <Typography style={{ fontWeight: 500, lineHeight: '22px', textAlign: 'center' }}>{data.name}</Typography>
        </Box>
      </Box>
    </DLNodeCard>
  );
};

export const ZoneCard = ({ item, onClickCard, locationId, gatewayId, onRefreshZone }: { item: ControlLocationNodeType; onClickCard: () => void; locationId: string; gatewayId: string, onRefreshZone: () => void }) => {
  const [updateZoneControl, { isLoading: isLoadingUpdate }] = useUpdateZoneControlMutation();
  const {
    auth: { currentUser },
  } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { setSnackbar } = useSnackbar();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, zoneId: string) => {
    if (currentUser && locationId && gatewayId) {
      setIsLoading(true)
      updateZoneControl({
        agencyId: currentUser.sub_id,
        locationId: locationId,
        gatewayId: gatewayId,
        nodeId: zoneId,
        data: {'power':event.target.checked},
      }).then((res:any) => {
        if(res && res.data?.success === true){
          setTimeout(()=>{
            setIsLoading(false)
            onRefreshZone();
            setSnackbar({ open: true, message: 'Cập nhật zone thành công', severity: 'success' });
          },2000)
        }
      });
    }
  };
 
  return (
    <DLNodeCard onClick={onClickCard} >
      {isLoading &&
      <LoadingCard>
        <Box>
          <CircularProgress />
        </Box>
      </LoadingCard>
      }
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="center" >
          <Box display="flex" alignItems="center" justifyContent="center">
            <img src={Zone} alt="" />
          </Box>
          <Box ml={2}>
            <Typography style={{ fontWeight: 500, lineHeight: '22px', textAlign: 'center' }}>{item.name}</Typography>
          </Box>
        </Box>
        <Box onClick = {(event:React.MouseEvent<HTMLElement>) => event.stopPropagation()} >
          <Switch 
            disabled={isLoading}
            checked={Boolean(item.state?.power)}
            onChange={(event) =>
              handleChange(event, item.id)
            }
            />
        </Box>
      </Box>
    </DLNodeCard>
  );
};
